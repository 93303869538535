/*==========================================================================

   mixin

===========================================================================*/

// ==========================================================================
//
//   media queries
//
// ==========================================================================
@mixin mq-sp-min {
	@media #{$bp-sp-min} {
		@content;
	}
}

@mixin mq-sp {
	@media #{$bp-sp} {
		@content;
	}
}

@mixin mq-tb {
	@media #{$bp-tb} {
		@content;
	}
}

@mixin mq-pc {
	@media #{$bp-pc} {
		@content;
	}
}
